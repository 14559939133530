<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Toko"
    allow-clear
    show-search
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="select"
    @deselect="deselect"
    @dropdownVisibleChange="dropdownVisibleChange"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
  >
    <template #notFoundContent>
      <template v-if="fetching"> <a-spin size="small" /> Sedang mencari ... </template>
      <template v-else>Tidak ditemukan/Ketikkan Kode / Nama Toko</template>
    </template>
    <a-select-option v-for="d in data" :key="d.code" :label="d.code + ' - ' + d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.code + ' - ' + d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, merge, keyBy, values } from 'lodash'

export default {
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    mode: {
      type: String,
      default: 'multiple',
    },
    joinOptions: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value', 'select'],
  setup(props, { emit }) {
    const data = ref([])
    const findText = ref(null)
    const timeout = ref(null)
    const fetching = ref(false)
    const { value } = toRefs(props)
    const loading = ref(false)
    const joinOptions = toRefs(props).joinOptions

    const fetchData = debounce((q = null) => {
      loading.value = true
      apiClient
        .get('/api/filter/customer?active=1', { params: { q } })
        .then(response => {
          const sorted = response.data
          // if (joinOptions.value.length > 0) {
          //   const merged = merge(keyBy(joinOptions.value, 'id'), keyBy(sorted, 'id'))
          //   sorted = values(merged)
          // }
          data.value = sorted.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
        })
        .catch(err => console.log(err))
        .finally(() => {
          loading.value = false
        })
    }, 500)

    onMounted(() => {
      try {
        if (joinOptions.value.length > 0) {
          data.value = joinOptions.value.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
        } else {
          fetchData()
        }
      } catch (error) {
        console.error('failed join options', error)
      }
    })

    watch(value, (after, before) => {
      if (after === null || after === undefined || after === []) fetchData()
    })

    const onSearch = value => {
      fetching.value = true
      if (timeout.value) {
        clearTimeout(timeout.value)
      }
      timeout.value = setTimeout(() => {
        findText.value = value
        fetchData(value)
          .then(response => {
            fetching.value = false
          })
          .catch(err => {
            if (err !== 'aborted') {
              fetching.value = false
            }
          })
      }, 300)
    }

    const highlight = value => {
      if (value === undefined || value === null) return
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const select = value => {
      if (findText.value !== null) {
        fetchData()
        findText.value = null
      }
      emit('select', value)
    }

    const deselect = value => {
      console.log({ deselect: '', value })
    }

    const dropdownVisibleChange = value => {
      if (findText.value !== null) {
        fetchData()
        findText.value = null
      }
    }

    return {
      fetching,
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
      deselect,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>